<template>

  <div class="tab-content col-lg-10 col-md-10 ">
    <div class="iq-card-body p-0">
      <div class="row">
        <!-- b-statements__aside -->
        <OtherLeftMenu :menuId="selectedId"></OtherLeftMenu>
        <div class="col-lg-9 col-md-9">
          <!-- 訂閱價格設定 -->
          <!-- {{user}} -->
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('label.subscriptionPriceSetting')}}</h4>
            </template>
            <template v-slot:body>
              <div class="form-group">
                <label class="form-label" for="colFormLabel">{{$t('label.name')}}</label>
                <span class="float-end">{{$t('label.subscriptionCustomizedDesc')}} </span>
                <input v-model="form.program_title" @blur="v$.form.$touch()" :class="{ 'is-invalid': v$.form.program_title.$error }" type="program_title" class="form-control" id="colFormLabel"
                  :placeholder="$t('label.subscriptionPlanPlaceholder')" />
                <span v-if="v$.form.program_title.$error" class="invalid-feedback">{{ $t('messages.required') }}</span>
              </div>
              <div class="form-group">
                <label class="form-label" for="colFormLabel">{{$t('label.monthlyPrice')}}</label>
                <input v-model="form.program_price" @blur="v$.form.$touch()" :class="{ 'is-invalid': v$.form.program_price.$error }" type="number" class="form-control" id="colFormLabel" placeholder="$" />
                <span v-if="v$.form.program_price.$error" class="invalid-feedback">{{ $t('messages.required') }}</span>
              </div>
              <div class="form-group">
                <label class="form-label" for="colFormLabel">{{$t('label.desc')}}</label>
                <textarea v-model="form.program_desc" @blur="v$.form.$touch()" :class="{ 'is-invalid': v$.form.program_desc.$error }" type="textarea" class="form-control" id="colFormLabel"
                  :placeholder="$t('label.subscriptionPlanDescPlaceholder')" />
                <span v-if="v$.form.program_desc.$error" class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
                <div class="d-grid gap-2 pb-4">
                  <button @click="onSubmit" type="button" class="btn btn-primary rounded-pill">
                    <i v-show="btnLoader" class="fa fa-spinner fa-spin"></i>
                    {{$t('button.add')}}
                  </button>
                </div>

                <ul class="request-list list-inline m-0 p-0">
                  <li class="" v-for="(r, index) in rows.program" :key="index">
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                      <h4>{{ r.program_price }}</h4>
                      <div class="flex-grow-1 ms-3">
                        <h6>{{ r.program_title }} <span class="text-danger" v-if="r.is_default">(
                            {{$t('label.defaultPrice')}})</span></h6>
                        <p class="mb-0">{{ r.program_desc }}</p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end">
                      <div v-if="!r.is_default" class="d-flex align-items-center mt-2 mt-md-0 me-2">
                        <button @click="onDefault(r)"
                          class="btn-sm  btn-success rounded">{{$t('label.setDefault')}}</button>
                      </div>
                      <div class="d-flex align-items-center mt-2 mt-md-0">
                        <button @click="onDel(r)" class="btn-sm  btn-secondary rounded">{{$t('button.del')}}</button>
                      </div>
                    </div>
                  </li>
                </ul>
              </template>
            </iq-card>
            <iq-card>
              <!-- 簡介促銷活動 -->
              <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('label.promotionAction')}}</h4>
              </template>
              <template v-slot:body>
                <!-- 折扣率 -->
                <div class="form-group">
                  <label class="form-label" for="colFormLabel">{{$t('label.program')}}</label>
                  <span class="float-end">{{$t('label.promotionDesc')}}</span>
                  <select v-model="promotionsForm.program_id" class="form-select"
                    :class="{ 'is-invalid': v$.promotionsForm.program_id.$error }">
                    <option value="0" selected>{{$t('label.selectPromotion')}}</option>
                    <option v-for="(r,index) in rows.program" v-bind:key="index" :value="r.program_id">
                      {{r.program_title}}
                    </option>
                  </select>
                  <span v-if="v$.promotionsForm.program_id.$error"
                    class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
                <!-- 月份 -->
                <div class="form-group">
                  <label class="form-label" for="colFormLabel">{{$t('label.duration')}}</label>
                  <select v-model="promotionsForm.promotions_month" class="form-select"
                    :class="{ 'is-invalid': v$.promotionsForm.promotions_month.$error }">
                    <option v-for="n in 12" v-bind:key="n" :value="n">{{n}} months</option>
                  </select>
                  <span v-if="v$.promotionsForm.promotions_month.$error"
                    class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>

                <div class="form-group">
                  <label class="form-label" for="colFormLabel">{{$t('label.discountRate')}}</label>
                  <span class="float-end">新訂閱者或已過期訂閱者提供免費試用或折扣訂閱服務。</span>
                  <select v-model="promotionsForm.promotions_discount" class="form-select"
                    :class="{ 'is-invalid': v$.promotionsForm.promotions_discount.$error }">
                    <option v-for="(n,index) in 51" v-bind:key="index" :value="index">{{index}}% discount</option>
                  </select>
                  <span v-if="v$.promotionsForm.promotions_discount.$error"
                    class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
                <!-- 促銷到期天數 -->
                <div class="form-group">
                  <label class="form-label" for="colFormLabel"> {{$t('label.expirationPromotionsDays')}}</label>
                  <select v-model="promotionsForm.promotions_expire_days" class="form-select"
                    :class="{ 'is-invalid': v$.promotionsForm.promotions_expire_days.$error }">
                    <option v-for="n in 30" v-bind:key="n" :value="n">{{$t('label.promotions')}} {{n}}
                      {{$t('label.daysDue')}}</option>
                  </select>
                  <span v-if="v$.promotionsForm.promotions_expire_days.$error"
                    class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
                <!-- 訂閱者數量限制 -->
                <div class="form-group">
                  <label class="form-label" for="colFormLabel">{{$t('label.freeTrialSubscriberLimit')}}</label>
                  <select v-model="promotionsForm.promotions_subscribe_counts" class="form-select"
                    :class="{ 'is-invalid': v$.promotionsForm.promotions_subscribe_counts.$error }">
                    <option v-for="n in 100" v-bind:key="n" :value="n">{{n}} {{$t('label.subscribers')}}</option>
                  </select>
                  <span v-if="v$.promotionsForm.promotions_subscribe_counts.$error"
                    class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
                <!-- 描述 -->
                <div class="form-group">
                  <label class="form-label" for="colFormLabel">{{$t('label.desc')}}</label>
                  <textarea v-model="promotionsForm.promotions_message" @blur="v$.form.$touch()"
                    :class="{ 'is-invalid': v$.promotionsForm.promotions_message.$error }" type="textarea"
                    class="form-control" id="colFormLabel" :placeholder="$t('label.promotionsDescPlaceholder')" />
                  <span v-if="v$.promotionsForm.promotions_message.$error"
                    class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
                <!-- 促銷對象 -->
                <div class="form-group">
                  <label class="form-label">{{$t('label.newSubscribers')}}</label>
                  <div class="float-end form-check form-switch">
                    <input :class="{ 'is-invalid': v$.promotionsForm.promotions_type.$error }"
                      v-model="promotionsForm.promotions_type" value="new" class="form-check-input" type="checkbox"
                      id="flexSwitchCheckChecked" />
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label">{{$t('label.expiredSubscribers')}}</label>
                  <div class="float-end form-check form-switch">
                    <input :class="{ 'is-invalid': v$.promotionsForm.promotions_type.$error }"
                      v-model="promotionsForm.promotions_type" value="exp" class="form-check-input" type="checkbox"
                      id="flexSwitchCheckChecked" />
                  </div>
                  <p v-if="v$.promotionsForm.promotions_type.$error" class="text-danger">{{ $t('messages.required') }}
                  </p>
                </div>
                <!-- {{v$.promotionsForm.promotions_type}} -->
                <div class="d-grid gap-2 pb-4">
                  <button v-if="rows.program" @click="onPromotionsSubmit" type="button"
                    class="btn btn-primary rounded-pill" :disabled="rows.program.length === 0">
                    <i v-show="btnLoader" class="fa fa-spinner fa-spin"></i>
                    {{$t('button.add')}}
                  </button>
                </div>
                <ul class="request-list list-inline m-0 p-0">
                  <li v-for="(r, index) in rows.promotions" :key="index">
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                      <h4>{{ r.promotions_discount }}</h4>
                      <div class="flex-grow-1 ms-3">
                        <p class="mb-0"> {{ r.program_title }} </p>
                        <p class="mb-0">{{$t('label.promotions')}}{{$t('label.expired')}} {{ r.promotions_expire_date }}
                        </p>
                        <p class="mb-0">{{$t('label.promotions')}}{{$t('label.quantity')}}
                          {{ r.promotions_subscribe_counts }} </p>
                        <p class="mb-0">{{$t('label.used')}} {{ r.now_subscribe_counts }} </p>
                        <p class="mb-0">{{$t('label.desc')}} {{ r.promotions_message }} </p>
                        <p class="mb-0">{{$t('label.object')}}
                          <span v-for="(rr, index) in r.promotions_type" :key="index">
                            <template v-if="rr === 'new'">
                              <span class="text-info">
                                ({{$t('label.newSubscribers')}})
                              </span>
                            </template>
                            <template v-if="rr === 'exp'">
                              <span class="text-info">
                                ({{$t('label.expiredSubscribers')}})
                              </span>
                            </template>
                          </span>
                        </p>
                      </div>
                    </div>

                    <div class="d-flex justify-content-end mt-2 mt-md-0">
                      <button @click="onPromotionsDel(r)"
                        class="btn-sm btn-secondary rounded">{{$t('button.del')}}</button>
                    </div>
                  </li>
                </ul>
              </template>
            </iq-card>
            <!-- 訂閱綑綁 -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('label.subscriptionBundle')}}</h4>
              </template>
              <template v-slot:body>
                <div class="form-group">
                  <label class="form-label" for="colFormLabel">{{$t('label.discountRate')}}</label>
                  <span class="float-end">{{$t('label.use')}} <span
                      class="text-danger">{{$t('label.defaultPrice')}}</span>
                    {{$t('label.bundleDesc')}}</span>

                  <select v-model="bundleForm.subscription_bundle_discount" class="form-select"
                    :class="{ 'is-invalid': v$.bundleForm.subscription_bundle_discount.$error }">
                    <option v-for="(n,index) in 51" v-bind:key="index" :value="index">{{index}}% discount</option>
                  </select>
                  <span v-if="v$.bundleForm.subscription_bundle_discount.$error"
                    class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
                <div class="form-group">
                  <label class="form-label" for="colFormLabel">{{$t('label.duration')}}</label>
                  <select v-model="bundleForm.subscription_bundle_duration" class="form-select"
                    :class="{ 'is-invalid': v$.bundleForm.subscription_bundle_duration.$error }">
                    <option v-for="n in 11" v-bind:key="n+1" :value="n+1">{{n+1}} months</option>
                  </select>
                  <span v-if="v$.bundleForm.subscription_bundle_duration.$error"
                    class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>

                <div class="d-grid gap-2 pb-4">
                  <button @click="onBundleSubmit" type="button" class="btn btn-primary rounded-pill">
                    <i v-show="btnLoader" class="fa fa-spinner fa-spin"></i>
                    {{$t('button.add')}}
                  </button>
                </div>
                <ul class="request-list list-inline m-0 p-0">
                  <li class="d-flex align-items-center justify-content-between flex-wrap"
                    v-for="(r, index) in rows.subscription_bundle" :key="index">
                    <h4>{{ r.subscription_bundle_amount }}</h4>
                    <div class="flex-grow-1 ms-3">
                      <h6>{{$t('label.total')}} {{ r.subscription_bundle_duration }} months</h6>
                      <p> {{$t('label.enjoy')}} {{ r.subscription_bundle_discount }} discount</p>
                    </div>
                    <div class="d-flex align-items-center mt-2 mt-md-0">
                      <button @click="onDelBundle(r)"
                        class="btn-sm  btn-secondary rounded">{{$t('button.del')}}</button>
                    </div>
                  </li>
                </ul>
              </template>

            </iq-card>
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('label.freeTrialLink')}}</h4>
              </template>
              <template v-slot:body>
                <div class="form-group">
                  <label class="form-label" for="colFormLabel"> {{$t('label.expirationDays')}}</label>
                  <span class="float-end">{{$t('label.freeTrialDesc')}}</span>
                  <select v-model="trialsForm.trials_expire_days" class="form-select"
                    :class="{ 'is-invalid': v$.trialsForm.trials_expire_days.$error }">
                    <option v-for="n in 30" v-bind:key="n" :value="n">{{$t('label.discount')}} {{n}}
                      {{$t('label.daysDue')}}</option>
                  </select>
                  <span v-if="v$.trialsForm.trials_expire_days.$error"
                    class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
                <div class="form-group">
                  <label class="form-label" for="colFormLabel">{{$t('label.subscriptionDays')}}</label>
                  <select v-model="trialsForm.trials_subscribe_days" class="form-select"
                    :class="{ 'is-invalid': v$.trialsForm.trials_subscribe_days.$error }">
                    <option v-for="n in 365" v-bind:key="n" :value="n">{{n}} {{$t('label.days')}}</option>
                  </select>
                  <span v-if="v$.trialsForm.trials_subscribe_days.$error"
                    class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
                <!-- 訂閱者數量限制 -->
                <div class="form-group">
                  <label class="form-label" for="colFormLabel">{{$t('label.freeTrialSubscriberLimit')}}</label>
                  <select v-model="trialsForm.trials_subscribe_counts" class="form-select"
                    :class="{ 'is-invalid': v$.trialsForm.trials_subscribe_counts.$error }">
                    <option v-for="n in 100" v-bind:key="n" :value="n">{{n}} {{$t('label.subscribers')}}</option>
                  </select>
                  <span v-if="v$.trialsForm.trials_subscribe_counts.$error"
                    class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
                <div class="d-grid gap-2 pb-4">
                  <button @click="onTrialsSubmit" type="button" class="btn btn-primary rounded-pill">
                    <i v-show="btnLoader" class="fa fa-spinner fa-spin"></i>
                    {{$t('button.add')}}
                  </button>
                </div>
                <ul class="request-list list-inline m-0 p-0">
                  <li class="" v-for="(r, index) in rows.trials" :key="index">
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                      <h4>{{ r.subscription_bundle_amount }}</h4>
                      <div class="flex-grow-1 ms-3">
                        <h6>{{ r.trials_subscribe_days }} {{$t('label.days')}} {{$t('label.trials')}}</h6>
                        <p class="mb-0">{{$t('label.linkExpired')}} {{ r.trials_expire_date }} </p>
                        <p class="mb-0">{{$t('label.trials')}} {{ r.trials_subscribe_counts }} </p>
                        <p class="mb-0">{{$t('label.used')}} {{ r.now_subscribe_counts }} </p>
                      </div>

                    </div>
                    <div class="d-flex justify-content-end">
                      <div v-if="!r.is_default" class=" mt-2 mt-md-0 me-2">
                        <button @click="onCopyText(r)"
                          class="btn-sm btn-success rounded">{{$t('label.copyLink')}}</button>
                      </div>
                      <div class=" mt-2 mt-md-0">
                        <button @click="onDelTrials(r)"
                          class="btn-sm btn-secondary rounded">{{$t('button.del')}}</button>
                      </div>
                    </div>
                  </li>
                </ul>
              </template>

            </iq-card>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import OtherLeftMenu from '../User/Components/OtherLeftMenu'
import { required, minValue } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { reactive, ref, onMounted, getCurrentInstance } from 'vue'
// import { commomData } from '@/utils/commonVmodel'
import { addProgram, delProgram, defaultProgram } from '@/api/program'
import { addPromotions, delPromotions } from '@/api/promotions'

import { getSettings } from '@/api/settings'
import {
  addSubscriptionBundle,
  delSubscriptionBundle
} from '@/api/subscription'
import { addTrials, delTrials } from '@/api/trials'
import { mapGetters } from 'vuex'

export default {
  name: 'OtherSeting',
  props: ['menuId'],
  components: {
    OtherLeftMenu
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      isPlan: 'User/isPlanState'
    })
  },
  data() {
    return {
      selectedId: 1
    }
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const createFormData = () => ({
      program_title: '',
      program_price: '',
      program_desc: ''
    })
    const createBundleFormData = () => ({
      subscription_bundle_discount: 0,
      subscription_bundle_duration: 2
    })
    const createTrialsFormData = () => ({
      trials_expire_days: 5,
      trials_subscribe_counts: 10,
      trials_subscribe_days: 7
    })
    const createPromotionsFormData = () => ({
      program_id: 0,
      promotions_month: 1,
      promotions_discount: 0,
      promotions_message: '',
      promotions_subscribe_counts: 10,
      promotions_expire_days: 5,
      // promotions_type: ['all', 'exp']
      promotions_type: []
    })

    const $toast = proxy.$toast
    const $copyText = proxy.$copyText
    const v$ = useVuelidate()
    const btnLoader = ref(false)
    const form = reactive(createFormData())
    const bundleForm = reactive(createBundleFormData())
    const trialsForm = reactive(createTrialsFormData())
    const promotionsForm = reactive(createPromotionsFormData())

    const rows = ref({})

    const onSubmit = () => {
      v$.value.form.$touch()
      if (v$.value.form.$error) {
        return
      }
      addProgram(form)
        .then(res => {
          $toast.show(res.message)
          btnLoader.value = false
          Object.assign(form, createFormData())
          v$.value.form.$reset()
          getRows()
        })
        .catch(err => {
          btnLoader.value = false
          console.log(err)
          $toast.show(err.response.data.message)
        })
    }
    const onBundleSubmit = () => {
      v$.value.bundleForm.$touch()
      if (v$.value.bundleForm.$error) {
        return
      }
      addSubscriptionBundle(bundleForm)
        .then(res => {
          $toast.show(res.message)
          btnLoader.value = false
          Object.assign(bundleForm, createBundleFormData())
          getRows()
        })
        .catch(err => {
          btnLoader.value = false
          console.log(err)
          $toast.show(err.response.data.message)
        })
    }

    const onPromotionsSubmit = () => {
      v$.value.promotionsForm.$touch()
      if (v$.value.promotionsForm.$error) {
        return
      }
      addPromotions(promotionsForm)
        .then(res => {
          $toast.show(res.message)
          btnLoader.value = false
          Object.assign(promotionsForm, createPromotionsFormData())
          v$.value.promotionsForm.$reset()
          getRows()
        })
        .catch(err => {
          btnLoader.value = false
          console.log(err)
          $toast.show(err.response.data.message)
        })
    }

    const onTrialsSubmit = () => {
      v$.value.trialsForm.$touch()
      if (v$.value.trialsForm.$error) {
        return
      }
      addTrials(trialsForm)
        .then(res => {
          $toast.show(res.message)
          btnLoader.value = false
          Object.assign(trialsForm, createTrialsFormData())
          getRows()
        })
        .catch(err => {
          btnLoader.value = false
          console.log(err)
          $toast.show(err.response.data.message)
        })
    }
    const onDelBundle = r => {
      delSubscriptionBundle(r)
        .then(res => {
          console.log(res)
          getRows()
        })
        .catch(err => {
          console.log(err)
          $toast.show(err.response.data.message)
        })
    }
    const onDel = r => {
      delProgram(r)
        .then(res => {
          console.log(res)
          getRows()
        })
        .catch(err => {
          console.log(err)
          $toast.show(err.response.data.message)
        })
    }
    const onDelTrials = r => {
      delTrials(r)
        .then(res => {
          console.log(res)
          getRows()
        })
        .catch(err => {
          console.log(err)
          $toast.show(err.response.data.message)
        })
    }
    const onPromotionsDel = r => {
      delPromotions(r)
        .then(res => {
          console.log(res)
          getRows()
        })
        .catch(err => {
          console.log(err)
          $toast.show(err.response.data.message)
        })
    }
    const onDefault = r => {
      defaultProgram(r)
        .then(res => {
          console.log(res)
          getRows()
        })
        .catch(err => {
          console.log(err)
          $toast.show(err.response.data.message)
        })
    }

    const onCopyText = r => {
      // r.trials_url
      const url = `${process.env.VUE_APP_FRONT_END_URL}subscription?trialsToken=${r.trials_url}`
      $copyText(url)
        .then(() => {
          $toast.show('複製成功')
        })
        .catch(() => {
          $toast.show(`can't copy`)
        })
    }

    const getRows = () => {
      getSettings()
        .then(res => {
          rows.value = res
        })
        .catch(err => {
          console.log(err)
          $toast.show(err.response.data.message)
        })
    }
    onMounted(() => {
      getRows()
    })
    return {
      $copyText,
      v$,
      form,
      onSubmit,
      btnLoader,
      rows,
      onDel,
      onBundleSubmit,
      onDelBundle,
      bundleForm,
      onDefault,
      onTrialsSubmit,
      onDelTrials,
      trialsForm,
      onCopyText,
      onPromotionsSubmit,
      onPromotionsDel,
      promotionsForm
    }
  },

  validations() {
    return {
      form: {
        program_title: { required },
        program_price: { required },
        program_desc: { required }
      },
      bundleForm: {
        subscription_bundle_discount: { required },
        subscription_bundle_duration: { required }
      },
      trialsForm: {
        trials_expire_days: { required },
        trials_subscribe_counts: { required },
        trials_subscribe_days: { required }
      },
      promotionsForm: {
        promotions_discount: { required },
        promotions_message: { required },
        promotions_subscribe_counts: { required },
        promotions_expire_days: { required },
        promotions_type: { required },
        promotions_month: { required },
        program_id: { required, minValue: minValue(1) }
      }
    }
  }
}
</script>
