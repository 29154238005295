import request from '../utils/request'

// API-F27 - [TrialsController] trials/create 免費試用鏈接
export function addTrials(data) {
  return request({
    url: `/trials/create`,
    method: 'post',
    data
  })
}

// API-F32 - [TrialsController] trials/{trials_id}/delete 刪除免費試用鏈接
export function delTrials(data) {
  return request({
    url: `trials/${data.trials_id}/delete`,
    method: 'delete'
  })
}
