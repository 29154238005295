import request from '../utils/request'

// API-F26 - [ProgramController] program/create 新增方案
export function addProgram(data) {
  return request({
    url: `/program/create`,
    method: 'post',
    data
  })
}

// API-F26 - [ProgramController] program/create 新增方案
export function delProgram(data) {
  return request({
    url: `/program/${data.program_id}/delete`,
    method: 'delete'
  })
}

//API-F34 - [ProgramController] program/{program_id}/default 預設方案
export function defaultProgram(data) {
  return request({
    url: `/program/${data.program_id}/default`,
    method: 'put'
  })
}
