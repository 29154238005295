import request from '../utils/request'

// API-F29 - [PromotionsController] promotions/create 新增簡介推廣活動
export function addPromotions(data) {
  return request({
    url: `/promotions/create`,
    method: 'post',
    data
  })
}

// API-F35 - [PromotionsController] promotions/{promotions_id}/delete 刪除簡介推廣活動
export function delPromotions(data) {
  return request({
    url: `/promotions/${data.promotions_id}/delete`,
    method: 'delete'
  })
}
