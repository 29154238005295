<template>
  <div class="col-lg-3">
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{$t('label.otherSettings')}}</h4>
      </template>
      <template v-slot:body>
        <div class="iq-earning-page">
          <ul class="earning-list p-0 m-0">
            <li v-for="(item, index) in filteredList" :key="index" @click="selectProject(item)"
              :class="`${item.id === selected ? 'active' : ''}`">
              <router-link :to="{ name: item.routeName }"><i :class="`${item.icon}`"></i>{{ item.title }}</router-link>
            </li>
          </ul>
        </div>
      </template>
    </iq-card>
  </div>
</template>
<script>
export default {
  name: 'OtherLeftMenu',
  props: ['menuId'],
  data() {
    return {
      selected: 1,
      menuList: [
        {
          id: 1,
          title: this.$t('label.subscriptionPriceSetting'),
          routeName: 'user.other-setting',
          icon: 'fa fa-dollar-sign me-2'
        },
        {
          id: 2,
          title: this.$t('label.chatSettings'),
          routeName: 'user.chat-seting',
          icon: 'fa fa-edit me-2'
        },
        {
          id: 3,
          title: this.$t('label.qrCode'),
          routeName: 'user.qr-seting',
          icon: 'fa fa-qrcode me-2'
        }
      ],
      search: '',
      project: {
        id: 0,
        title: ''
      }
    }
  },
  methods: {
    selectProject() {
      this.selected = this.menuId
    }
  },
  computed: {
    filteredList() {
      return this.menuList.filter((item) => {
        return item.title.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  created() {
    this.selected = this.menuId
  },
  mounted() {}
}
</script>
